<template>
	<div class="header" :style="{position:positionFixed?'fixed':'sticky'}" :class="{ 'has-bg': isBackgroundVisible }">
		<div class="content">
			<div class="logo" @click="onIndex()"></div>
			<div class="navBox">
				<div class="nav" @click="MethodologyUrl()">{{languageData?.index_37}}</div>
			</div>
			<div class="btnBox">
				<div class="btn1" @click="onshowLogin(3)">{{languageData?.index_4}}</div>
				<div class="btn2" @click="onshowLogin(1)">{{languageData?.index_5}}</div>
			</div>
		</div>
		<div class="loginContent" v-if="showLogin">
			<div class="bg"></div>
			<div class="contentT" :class="loginIdx == 3?'contentT3':''" >
				<div class="box">
					<img class="close" src="@/assets/i38.png" alt="" @click="onshowLogin">
					<div class="fromBox" v-if="loginIdx == 1">
						<div class="title">{{languageData?.index_38}}</div>
						<div class="navBox">
							<div class="t" :class="navIdx == 1?'active':''" @click="onNav(1)">{{languageData?.index_39}}</div>
						</div>
						<div class="inputBox" v-if="navIdx == 1">
							<div class="input">
								<div class="lable2">{{languageData?.index_40}}</div>
								<el-input v-model="formData.email"  autocomplete="off" />
							</div>
							<div class="input">
								<div class="lable2">{{languageData?.index_41}}</div>
								<el-input v-model="formData.password" type="password"  autocomplete="new-password" />
							</div>
						</div>
						<div class="inputBox" v-if="navIdx == 2">
							<div class="input">
								<el-input v-model="formData.email" placeholder="Email" autocomplete="off" />
							</div>
							<div class="input">
								<el-input class="codeIpnut" v-model="formData.code" placeholder="code" autocomplete="off" />
								<div class="yanma" @click="!disabledT?sendsmsCode(2):''">{{ time }}</div>
							</div>
						</div>
						<div class="forgotBox">
							<div class="jizhu" @click="ondayHas()">
								<img class="i" src="../assets/i39.png" alt="" v-if="dayHas">
								<img class="i" src="../assets/i40.png" alt="" v-else>
								<div class="t">{{languageData?.index_42}}</div>
							</div>
							<div class="wang" @click="loginIdx = 2">{{languageData?.index_43}}</div>
						</div>
						<div class="btnT" @click="navIdx == 1?onLogin():onLoginCode()">{{languageData?.index_44}}</div>
						<div class="error" v-if="errMsg">{{errMsg}}</div>
						<div class="error" style="opacity: 0;" v-else>  </div>

						<div class="msgT" @click="loginIdx = 3">
							{{languageData?.index_45}}
							<span class="span">{{languageData?.index_46}}</span>
						</div>
					</div>
					<div class="fromBox " v-if="loginIdx == 2">
						<div class="title">{{languageData?.index_47}}</div>
						<div class="inputBox">
							<div class="input">
								<div class="lable">{{languageData?.index_40}}</div>
								<el-input v-model="formData.email" autocomplete="off" />
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_66}}</div>
								<el-input class="codeIpnut" v-model="formData.code"  autocomplete="off" />
								<div class="yanma" @click="!disabledT?sendsmsCode(3):''">{{ time }}</div>
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_49}}</div>
								<el-input v-model="formData.password" type="password"  autocomplete="new-password" />
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_50}}</div>
								<el-input v-model="formData.password2" type="password"  autocomplete="new-password" />
							</div>
						</div>
						<div class="btnTT" @click="onResetPwd()">{{languageData?.index_51}}</div>
					</div>
					<div class="fromBox fromBox2" v-if="loginIdx == 3">
						<div class="title">{{formData.source}}</div>
						<div class="inputBox" >
							<div class="input">
								<div class="lable">{{languageData?.index_52}}</div>
								<el-input v-model="formData.company"  autocomplete="off" />
							</div>
							
							<div class="input">
								<div class="lable">{{languageData?.index_54}}</div>
								<el-input v-model="formData.name"  autocomplete="off" />
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_56}}</div>
								<el-input v-model="formData.position"  autocomplete="off" />
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_57}}</div>
								<el-input  v-model="formData.email"  autocomplete="off" />
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_64}}</div>
								<div class="newInput">
									<div class="sBox">
										<el-select-v2
											v-model="formData.country"
											:options="countryList"
											value-key="en"
											limit="3"
											:popper-append-to-body="false"
											:props="{
												label: 'en',
												value: 'en',
											}"
											placeholder="Please select"
										/>
									</div>
									<div class="pbox">
										<div class="yanma2">{{countryList.find((item:any) => item.en == formData.country)?.prefix }}</div>
										<el-input class="codeIpnut2" v-model="formData.phone"  autocomplete="off" />
									</div>
								</div>
								
							</div>
							<div class="input" v-if="formData.source == languageData?.index_27">
								<div class="lable">{{languageData?.index_59}}</div>
								<el-input  v-model="formData.message"  autocomplete="off" />
							</div>
							<div class="input" style="align-items: flex-start;" v-if="formData.source == languageData?.index_27">
								<div class="lable">{{languageData?.index_61}}</div>
								<el-upload ref="uploadRef" v-model:file-list="fileList" :show-file-list="false"  action="/api/Ajax/upload" :on-change="onChange" class="upload-demo" :auto-upload="true" >
									<template #trigger>
										<div class="uploadRef">
											<img class="i" src="@/assets/i125.png" alt="">
											<div class="t">{{languageData?.index_62}}</div>
										</div>
									</template>						
								</el-upload>
								<div class="fileList">
									<div class="list" v-for="(item,index) in fileList" :key="index">
										<img class="i" src="@/assets/i126.png" alt="">
										<div class="t">{{ item.name }}</div>
										<img class="d" src="@/assets/i38.png" alt="" @click.stop="delFile(index)">
									</div>
								</div>
							</div>
							
						</div>
						<div class="btnTT" @click="onContact()">{{languageData?.index_51}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent,ref,onMounted,onUnmounted,watch } from 'vue';
import {postApi,getApi} from '@/utils/index'
import { ElMessage } from 'element-plus'
import { useRouter } from "vue-router"
import type { UploadProps } from 'element-plus'

export default defineComponent({
	name: 'headerComp',
	props: {
		msg: String,
		positionFixed: Boolean,
		languageData: Object
	},
	setup(props) {
		const isBackgroundVisible = ref(false)
		const threshold = ref(120)
		const scrollTopT = ref(0)
		const countryList:any = ref()
		
		const handleScroll = () => {
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
			scrollTopT.value = scrollTop
      		isBackgroundVisible.value = scrollTop >= threshold.value?true:false
		}
		const router = useRouter()
		const navIdx = ref(1)
		const dayHas = ref(false)
		const fileList:any = ref([])
		const formData = ref({
			email: '',
			phone: '',
			password: '',
			password2: '',
			code: '',
			company: '',
			position: '',
			name: '',
			phone_prefix: '',
			source: props.languageData?.index_4,
			message: '',
			country: ''
		})
		const showLogin = ref(false)
		const time = ref(props.languageData?.index_66)
		const currentTime = ref(60)
		const interval = ref(0)
		const disabledT = ref(false)
		const loginIdx = ref(1)
		const browser = ref('')
		const system = ref('')
		const errMsg = ref('')
		const articleUrl = ref(null)
		const Methodology = ref(<any>{})
		const onshowLogin = (type:any,url = null,source = '') => {
			articleUrl.value = url
			showLogin.value = !showLogin.value
			loginIdx.value = type || 1
			formData.value = {
				email: '',
				phone: '',
				password: '',
				password2: '',
				code: '',
				company: '',
				position: '',
				name: '',
				phone_prefix: '',
				source: source || props.languageData?.index_4,
				message: '',
				country: ''
			}
			clearInterval(interval.value)
			time.value = props.languageData?.index_66
			currentTime.value = 60
			disabledT.value = false
		}
		
		const onNav = (index:any) => {
			navIdx.value = index
			formData.value = {
				email: '',
				phone: '',
				password: '',
				password2: '',
				code: '',
				company: '',
				position: '',
				name: '',
				phone_prefix: '',
				source: props.languageData?.index_4,
				message: '',
				country: ''
			}
		}
		const ondayHas = () => {
			dayHas.value = !dayHas.value
			if (dayHas.value) {
				localStorage.setItem('flagUser', `${Date.now() + 30 * 24 * 60 * 60 * 1000}`)
			} else {
				localStorage.removeItem('flagUser')
			}
		}
		const sendsmsCode = async (type:any) => {
			let data = {
				email: formData.value.email,
				codeType: type
			}
			
			let res:any = await postApi('/api/Useren/getCode',data)
			if (res.code == 0) {
				ElMessage.success(res.msg)
				getCode()
			}
		}
		const getCode = () => {
			let that = this;
			disabledT.value = true
			interval.value = setInterval(function () {
				currentTime.value--;
				time.value = `${currentTime.value}s`
				if (currentTime.value <= 0) {
					clearInterval(interval.value)
					time.value = props.languageData?.index_67
					currentTime.value = 60
					disabledT.value = false
				}
			}, 1000)
		}
		const onLogin = async () => {
			let data = {
				email: formData.value.email,
				pwd: formData.value.password,
				flag: dayHas.value?1:0,
				type: 1,
				browser: browser.value,
				system: system.value,
			}
			let res:any = await postApi('/api/Useren/userlogin',data)
	
			if (res.code == 0) {
				errMsg.value = ''
				if (articleUrl.value) {
					getUserInfo()
				} else {
					router.push({
						path: '/admin'
					})
				}
				
			} else {
				errMsg.value = res.msg
			}
			
		}
		const getUserInfo = async () => {
			let res = await postApi('/api/useren/getUserInfo',{})
			localStorage.setItem('userInfo',JSON.stringify(res.data))
			if (articleUrl.value) {
				window.location.href = articleUrl.value
			}
			
		}
		const onLoginCode = async () => {

			let data = {
				email: formData.value.email,
				code: formData.value.code,
				flag: dayHas.value?1:0,
				type: 2,
				browser: browser.value,
				system: system.value,
			}
			let res:any = await postApi('/api/Useren/userlogin',data)
			if (res.code == 0) {
				errMsg.value = ''
				if (articleUrl.value) {
					getUserInfo()
				} else {
					router.push({
						path: '/admin'
					})
				}
			} else {
				errMsg.value = res.msg
			}
			
		}

		const onResetPwd = async () => {

			let data = {
				email: formData.value.email,
				code: formData.value.code,
				pwd: formData.value.password,
				repwd: formData.value.password2
			}
			let res:any = await postApi('/api/Useren/retVeriCode',data)
			
			if (res.code == 0) {
				ElMessage.success(res.msg)
				loginIdx.value = 1
			} 
		}
		const onChange: UploadProps['onChange'] = (uploadFile, uploadFiles) => {
		
		}

		const onContact = async () => {
			let data:any = {
				email: formData.value.email,
				company: formData.value.company,
				contacts: formData.value.name,
				duties: formData.value.position,
				phone_prefix: countryList.value.find((item:any) => item.en == formData.value.country)?.prefix,
				phone: formData.value.phone,
				source: formData.value.source,
				country: formData.value.country,
				files: []
			}
			if (fileList.value.length > 0) {
				for(let i in fileList.value) {
					data.files.push(fileList.value[i].response.data.url)
				}
				
			}
			let res:any = await postApi('/api/Useren/register',data)
			if (res.code == 0) {
				ElMessage.success(res.msg)
				loginIdx.value = 1
				showLogin.value = false
				clearInterval(interval.value)
				time.value = props.languageData?.index_66
				currentTime.value = 60
				disabledT.value = false 
			} 
		}
		const onIndex = () => {
			router.push({
				path: '/',
			})
		}
		const getCountry = async () => {
	
			let res:any = await postApi('/api/Useren/getCountry',{})
			countryList.value = res.data

			
		}
		const delFile = async (index:any) => {
			fileList.value.splice(index,1)
		}
		const MethodologyUrl = () => {
			const routeUrl = router.resolve(`/articleDetailsd?code=Methodology`).href
			window.open(routeUrl, '_blank');
		}
				

		onMounted(() => {
			getCountry()
			window.addEventListener('scroll', handleScroll)
			const flagUser = localStorage.getItem('flagUser')
			
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
			isBackgroundVisible.value = scrollTop >= threshold.value?true:false
			if (flagUser) {
				if (flagUser && Date.now() > Number(flagUser)) {
					localStorage.removeItem('flagUser')
				} else {
					dayHas.value = true
				}
			}

			let userAgent = navigator.userAgent;  
			browser.value = userAgent
			system.value = userAgent
			if (userAgent.match(/opera/i)) {  
				browser.value = "Opera";  
			} else if (userAgent.match(/chrome/i)) {  
				browser.value  = "Chrome";  
			} else if (userAgent.match(/safari/i)) {  
				browser.value  = "Safari";  
			} else if (userAgent.match(/firefox/i)) {  
				browser.value  = "Firefox";  
			} else if (userAgent.match(/msie/i)) {  
				browser.value  = "Internet Explorer";  
			} else {  
				browser.value  = "no";  
			}  
		})
		onUnmounted(() => {
			window.removeEventListener('scroll', handleScroll)
		})
		return {
			onChange,
			fileList,
			countryList,
			isBackgroundVisible,
			navIdx,
			formData,
			dayHas,
			showLogin,
			time,
			disabledT,
			loginIdx,
			onNav,
			ondayHas,
			onshowLogin,
			sendsmsCode,
			onLogin,
			onLoginCode,
			onResetPwd,
			onContact,
			onIndex,
			errMsg,
			delFile,
			
			Methodology,
			MethodologyUrl
		}
	}
});
</script>

<style scoped lang="scss">
.header{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
	transition: all .5s ease-in-out;
	margin: auto;
	.content{
		display: flex;
		align-items: center;
		padding: 0 1.5rem;
		height: 3.6rem;
		.navBox{
			display: flex;
			align-items: center;
			.nav{
				font-size: 1rem;
				color: #F73100;
				position: relative;
				margin-left: 3.6rem;
				cursor: pointer;
				font-weight: 700;
			}
		}
		
		.logo{
			width: 7.6rem;
			height: 1.9rem;
			background-image: url('@/assets/logo.svg');
			background-size:cover;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.btnBox{
			display: flex;
			align-items: center;
			margin-left: auto;
			.btn1{
				color: #F73100;
				font-size: .8rem;
				padding: .4rem 1.6rem;
				border: solid 1px #F73100;
				border-radius: .2rem;
				cursor: pointer;
			}
			.btn2{
				color: #fff;
				font-size: .8rem;
				padding: .4rem 1.6rem;
				border: solid 1px #F73100;
				border-radius: .2rem;
				background: #F73100;
				margin-left: .6rem;
				cursor: pointer;
			}
		}
	}
	
}
.has-bg{
	background-color: #fff;
	box-shadow: 0px -5px 8px 0px rgba(255, 255, 255, 0.8),0px 8px 35px 0px rgba(126, 65, 53, 0.1);
}
.loginContent{
	.bg{
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
		z-index: 10000;
	}
	.contentT{
		position: fixed;
		width: 49.4rem;
		background-color: #fff;
		background-image: url('../assets/adminbg2.png');
		background-repeat: no-repeat;
		background-size: cover;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 10001;
		border-radius: .6rem;
		height: 26.9rem;
		display: flex;
		justify-content: center;
		.box{
			padding: 1.8rem;
			flex: 1;
			.close{
				width: 1rem;
				height: 1rem;
				margin-left: auto;
				cursor: pointer;
			}
			.fromBox{
				padding: 1.2rem;
				width: 18.5rem;
				.error{
					font-size: .6rem;
					color: #F73100;
					margin-top: .5rem;
					text-align: center;
					min-height: 1rem;
					line-height: 1rem;
				}
				.msgT{
					font-size: .65rem;
					color: #8C8C8C;
					cursor: pointer;
					margin-top: .5rem;
					text-align: center;
					.span{
						text-decoration: underline;
					}
				}
				.btnT{
					color: #F7FBFE;
					font-size: .75rem;
					font-weight: 500;
					line-height: 2.2rem;
					border-radius: .2rem;
					margin-top: .9rem;
					text-align: center;
					cursor: pointer;
					width: 17.5rem;
					background: linear-gradient(0deg, rgba(247, 49, 0, 0.5), rgba(247, 49, 0, 0.5)), radial-gradient(159% 159% at 29% -68%, rgba(253, 224, 209, 0.5) 26%, rgba(254, 207, 150, 0.19) 58%, rgba(243, 86, 55, 0) 99%), #F34F5A;
				}
				.btnTT{
					color: #F7FBFE;
					font-size: .75rem;
					font-weight: 500;
					line-height: 2.2rem;
					border-radius: .2rem;
					margin-top: 2rem;
					text-align: center;
					cursor: pointer;
					width: 17.5rem;
					background: linear-gradient(0deg, rgba(247, 49, 0, 0.5), rgba(247, 49, 0, 0.5)), radial-gradient(159% 159% at 29% -68%, rgba(253, 224, 209, 0.5) 26%, rgba(254, 207, 150, 0.19) 58%, rgba(243, 86, 55, 0) 99%), #F34F5A;
				}
				.forgotBox{
					display: flex;
					align-items: center;
					margin-top: 1rem;
					.jizhu{
						display: flex;
						align-items: center;
						cursor: pointer;
						line-height: 1;
						.i{
							width: .7rem;
							height: .7rem;
						}
						.t{
							font-size: .65rem;
							margin-left: .3rem;
							color: #8C8C8C;
						}
					}
					.wang{
						margin-left: auto;
						font-size: .65rem;
						color: #8C8C8C;
						cursor: pointer;
					}
				}
				
				.inputBox{
					margin-top: 1.2rem;
					.input{
						width: 100%;
						margin-bottom: .6rem;
						position: relative;
						display: flex;
						align-items: center;
						:deep(.is-focused){
							box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset;
						}
						:deep(.el-select){
							flex: 1;
							.el-select__wrapper{
								height: 2.4rem;
							}
						}
						.upload-demo{
							.uploadRef{
								background: rgba(247, 49, 0, 0.03);
								border: 1px dashed #F5BBB1;
								width: 6rem;
								height: 6rem;
								border-radius: .2rem;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								.i{
									width: 1.6rem;
									height: 1.6rem;
								}
								.t{
									font-size: .65rem;
									color: #F73100;
									margin-top: .35rem;
								}
							}
						}
						.fileList{
							flex: 1;
							margin-left: .6rem;
							
							.list{
								display: flex;
								align-items: center;
								background: rgba(0, 0, 0, 0.05);
								padding: 0 .4rem;
								border-radius: .2rem;
								margin-bottom: .3rem;
								height: 1.8rem;
								&:last-child{
									margin-bottom: 0;
								}
								.i{
									width: .8rem;
									height: .8rem;
								}
								.t{
									font-size: .65rem;
									margin-left: .4rem;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;
									overflow: hidden;
									flex: 1;
									word-break: break-all;
								}
								.d{
									width: .6rem;
									height: .6rem;
									margin-left: .3rem;
									cursor: pointer;
								}
							}
						}
						.yanma2{
							position: absolute;
							font-size: .65rem;
							color: #8C8C8C;
							left: 0;
							top: 50%;
							transform: translate(0,-50%);
							padding-left: 1rem;
							line-height: 1;
							cursor: pointer;
							width: 3.5rem;
							height: 2.4rem;
							display: flex;
							align-items: center;
							z-index: 9;
							&::after{
								content: '';
								position: absolute;
								width: 1px;
								height: .8rem;
								background-color: rgba(140, 140, 140, 0.5);
								left: 3rem;
								top: 50%;
								transform: translate(0,-50%);
							}
						}
						.yanma{
							position: absolute;
							font-size: .65rem;
							color: #8C8C8C;
							right: 1rem;
							top: 50%;
							transform: translate(0,-50%);
							padding-left: 1rem;
							line-height: 1;
							cursor: pointer;
							width: 3.5rem;
							text-align: center;
							&::after{
								content: '';
								position: absolute;
								width: 1px;
								height: 80%;
								background-color: rgba(140, 140, 140, 0.5);
								left: 0;
								top: 50%;
								transform: translate(0,-50%);
							}
						}
						&:last-child{
							margin-bottom: 0;
						}
						.lable{
							font-size: .65rem;
							width: 5.5rem;
							text-align: justify;
							margin-right: .9rem;
						}
						.lable2{
							font-size: .65rem;
							width: 3rem;
							text-align: justify;
							margin-right: .9rem;
						}
						.uploadRef{
							display: flex;
							flex: 1;
							.upload-demo{
								flex: 0 0 6rem;
							}
						}
						:deep(.el-input){
							flex: 1;
							line-height: 2.4rem;
							height: 2.4rem;
							font-size: .7rem;
							--el-input-hover-border: #8C8C8C;
							--el-input-focus-border: #8C8C8C;
							--el-input-hover-border-color: #8C8C8C;
							--el-input-clear-hover-color: #8C8C8C;
							--el-input-focus-border-color: #8C8C8C;
							--el-input-text-color: #434343;
							--el-input-width: 100%;
							.el-input__wrapper{
								padding: 0 1rem;
							}
						}
						.codeIpnut{
							:deep(.el-input__wrapper){
								padding-right: 35%;
							}
							
						}
						.codeIpnut2{
							:deep(.el-input__wrapper){
								padding-left: 3.5rem;
							}
							
						}
						.newInput{
							display: flex;
							align-items: center;
							flex: 1;
							.sBox{
								flex: 0 0 8rem;
							}
							.pbox{
								flex: 1;
								margin-left: .6rem;
								position: relative;
							}
						}
					}
				}
				.title{
					font-size: 1.3rem;
					font-weight: 700;
					color: #3D3D3D;
				}	
				.navBox{
					display: flex;
					align-items: center;
					margin-top: 1.5rem;
					.t{
						position: relative;
						font-size: .8rem;
						color: #434343;
						margin-right: 3.6rem;
						cursor: pointer;
						&:last-child{
							margin-right: 0;
						}
					}
					.active{
						font-size: .8rem;
						color: rgba(0, 0, 0, 1);
						font-weight: 700;
						&::after{
							content: '';
							position: absolute;
							width: 90%;
							bottom: -.15rem;
							background: radial-gradient(600% 600% at 25% -253%, rgba(255, 214, 200, 0.8) 0%, rgba(254, 217, 186, 0.304) 58%, rgba(255, 184, 170, 0) 97%), #FF7070;
							height: .5rem;
							left: 50%;
							z-index: -1;
							border-radius: .2rem;
							opacity: 0.6;
							transform: translate(-50%,0);
						}
					}

				}
			}
			.fromBox2{
				width: 20rem;
			}
		}
	}
	.contentT3{
		height: auto;
		background-image: url('../assets/adminbg3.png');
		width: 56.5rem;
		.box {
			.fromBox2{
				width: 26.2rem;
			}
		}
	}
}
</style>
